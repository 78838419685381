import React from "react";
import { NavLink } from "react-router-dom";
import {
  HomeIcon,
  NotificationIcon,
} from "../SvgIcons";
import { FaUsers } from "react-icons/fa6";
import { FaUserSlash } from "react-icons/fa6";
import { FaUserNurse } from "react-icons/fa6";
import { FaUserTie } from "react-icons/fa6";
import { FaUserShield } from "react-icons/fa";
import { RiPagesLine } from "react-icons/ri";
import HeartLogo from "../../assets/images/heart-logo.png"


const navigationItems = [
  { to: "/dashboard", label: "Home", icon: <HomeIcon /> },
  { to: "/manage-user", label: "Users", icon: <FaUsers /> },
  { to: "/deleted-users", label: "Deleted Users", icon: <FaUserSlash /> },
  { to: "/manage-officer", label: "Officers", icon: <FaUserNurse /> },
  { to: "/manage-agents", label: "Agents", icon: <FaUserTie /> },
  { to: "/manage-representatives", label: "Reps", icon: <FaUserShield /> },
  { to: "/notifications", label: "Notifications", icon: <NotificationIcon /> },
  { to: "/page-list/", label: "Pages", icon: <RiPagesLine /> },
];

const Admin = ({ setShowOffcanvas }) => {
  const handleItemClick = () => {
    setShowOffcanvas(false);
  };

  return (
    <div className="d-flex flex-column flex-shrink-0 h-100 sideBar">
      <ul className="nav nav-pills sidebarNav flex-column mb-auto">
        {navigationItems.map((item, index) => (
          <li className="nav-item" key={index} onClick={handleItemClick}>
            <NavLink
              to={item.to}
              className="nav-link"
              activeClassName="active"
            >
              {item.icon}
              {item.label}
            </NavLink>
          </li>
        ))}
      </ul>
      <div className="bottom-advert mt-auto p-3">
        <div className="bottom-image text-center my-3">
          <img src={HeartLogo} alt="Heart Logo" className="img-fluid" />
        </div>
        <p className="bottom-text small">
          The HEART Peer Support team is made up of CSCD employees trained to
          provide help to their colleagues following work-related critical
          incidents.
        </p>
        <p className="bottom-text small">
          For more information about HEART or to request a peer to contact you,
          contact <a href="mailto:peer.support@cscd.net">peer.support@cscd.net</a>.
        </p>
      </div>
    </div>
  );
};

export default Admin;
