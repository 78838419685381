import React from "react";
import { NavLink } from "react-router-dom";
import { FaUsers, FaUserNurse, FaUserTie } from "react-icons/fa6";
import {
  NotificationIcon,
} from "../SvgIcons";
import HeartLogo from "../../assets/images/heart-logo.png"

const Agent = ({ setShowOffcanvas }) => {
  const navLinks = [
    { to: "/manage-user", icon: <FaUsers />, text: "Users" },
    { to: "/manage-officer", icon: <FaUserNurse />, text: "Officers" },
    { to: "/manage-agents", icon: <FaUserTie />, text: "Agents" },
    { to: "/notifications", icon: <NotificationIcon />, text: "Notifications" },
  ];

  const handleNavLinkClick = () => {
    setShowOffcanvas(false);
  };

  return (
    <div className="d-flex flex-column flex-shrink-0 h-100 sideBar">
      <ul className="nav nav-pills sidebarNav flex-column mb-auto">
        {navLinks.map((link, index) => (
          <li className="nav-item" key={index} onClick={handleNavLinkClick}>
            <NavLink
              to={link.to}
              className="nav-link"
              activeClassName="active"
            >
              {link.icon}
              {link.text}
            </NavLink>
          </li>
        ))}
      </ul>

      <div className="bottom-advert mt-auto p-3">
        <div className="bottom-image text-center my-3">
          <img src={HeartLogo} alt="Heart Logo" className="img-fluid" />
        </div>
        <p className="bottom-text small">
          The HEART Peer Support team is made up of CSCD employees trained to
          provide help to their colleagues following work-related critical
          incidents.
        </p>
        <p className="bottom-text small">
          For more information about HEART or to request a peer to contact you,
          contact <a href="mailto:peer.support@cscd.net">peer.support@cscd.net</a>.
        </p>
      </div>
    </div>
  );
};

export default Agent;
