import { constants } from "./constants";
import {getPresignedUrl} from "../services/users.service";

window.Buffer = window.Buffer || require("buffer").Buffer;

export const MoneyFormat = (value) => {
    let currency = Intl.NumberFormat(constants.LOCALE, {
        style: "currency",
        currency: constants.CURRENCY,
        useGrouping: false,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });

    return currency.format(value)
}

export const randomIdWithName = (userName) => {
    let randomNumber = Math.random().nextInt(100000);
    return userName + randomNumber;
}


export const UploadImageToS3 = async (fileInput) => {
    const fileSize = fileInput.size;

    if (fileSize > parseInt(constants.S3CREDENTIAL.fileSize, 10)) {
        return {
            success: false,
            message: constants.ERRORMESSAGES.fileSize
        }
    }

    try {
        // Get presigned URL
        const payload = {
            extension : fileInput.type.split('/')[1]
        }
        const response = await getPresignedUrl(payload);
        const presigned_url = response.data.presigned_url;
        const object_url = response.data.object_url;

        // Upload file using presigned URL - matching curl's raw upload
        const uploadResponse = await fetch(presigned_url, {
            method: 'PUT',
            body: fileInput,
            mode: 'cors',
        });

        if (uploadResponse.ok) {
            return {
                success: true,
                message: constants.ERRORMESSAGES.fileSuccess,
                data: object_url
            }
        } else {
            const errorText = await uploadResponse.text();
            // console.error('Upload failed details:', {
            //     status: uploadResponse.status,
            //     statusText: uploadResponse.statusText,
            //     responseText: errorText,
            //     requestUrl: presigned_url,
            //     fileInfo: {
            //         type: fileInput.type,
            //         size: fileInput.size,
            //         name: fileInput.name
            //     }
            // });
            throw new Error(`Upload failed: ${uploadResponse.status} ${uploadResponse.statusText}`);
        }
    } catch (error) {
        console.error('Error uploading file:', error);
        return {
            success: false,
            message: constants.ERRORMESSAGES.fileError
        }
    }
}
