import React from "react";
import { NavLink } from "react-router-dom";
import {
  OrderIcon,
  NotificationIcon,
  ManageCategoryIcon,
  ManagepProductsIcon,
  ManageUsersIcon,
  ManageAgentsIcon,
  ManageVendorsIcon,
  ManagePaymentsIcon,
  ReportsIcon,
  SettingsIcon,
  HomeIcon,
  HelpIcon,
  ContactIcon,
} from "../SvgIcons";
import { constants } from "../../utils/constants";

import { FaUsers, FaUserNurse, FaUserTie } from "react-icons/fa6";
import HeartLogo from "../../assets/images/heart-logo.png"

const Representative = ({ setShowOffcanvas }) => {
  return (
    <div className="d-flex flex-column flex-shrink-0 h-100 sideBar">
      <ul className="nav nav-pills sidebarNav flex-column mb-auto">
        <li className="nav-item" onClick={() => setShowOffcanvas(false)}>
          <NavLink
            to="/dashboard"
            className="nav-link"
            activeClassName="active"
          >
            <HomeIcon />
            Home
          </NavLink>
        </li>
        <li className="nav-item" onClick={() => setShowOffcanvas(false)}>
          <NavLink
            to="/manage-user"
            className="nav-link"
            activeClassName="active"
          >
            <FaUsers />
            Users
          </NavLink>
        </li>
        <li className="nav-item" onClick={() => setShowOffcanvas(false)}>
          <NavLink
            to="/notifications"
            className="nav-link"
            activeClassName="active"
          >
            <NotificationIcon />
            Notifications
          </NavLink>
        </li>
      </ul>
      <div className="bottom-advert mt-auto p-3">
        <div className="bottom-image text-center my-3">
          <img src={HeartLogo} alt="Heart Logo" className="img-fluid" />
        </div>
        <p className="bottom-text small">
          The HEART Peer Support team is made up of CSCD employees trained to
          provide help to their colleagues following work-related critical
          incidents.
        </p>
        <p className="bottom-text small">
          For more information about HEART or to request a peer to contact you,
          contact <a href="mailto:peer.support@cscd.net">peer.support@cscd.net</a>.
        </p>
      </div>
    </div>
  );
};

export default Representative;
